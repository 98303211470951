// this template is used both in static pages (CDP) and PD pages (campaignMembership component)

@import "../05-components/tooltip-modal";
@import "../05-components/toggle-buttons";

// behavior on PD campaign page when placed after hero
.experience-campaign-assets-herocampaignbanner + .experience-campaign-layouts-membership {
    position: relative;
    top: -50px;
    z-index: 2;

    &.has-toggle {
        // handle to change if / once there is feedback
    }

    .membership__component {
        padding: 0 16px;
        background-color: transparent;
    }

    .membership__heading {
        display: none;
    }

    .toggle-buttons-wrapper {
        @include mq($not-mobile-tablet-pt) {
            top: -80px;
            width: auto;
            position: absolute;
            padding: 0;
            right: 0;
        }
    }
}

// behavior on campaign page when placed after general content hero
.content-page-banner {
    &.next-component-up {
        +.experience-campaign-layouts-membership {
            .membership__component {
                margin-top: 0;
                padding-top: 0;

                @include mq($not-mobile-tablet-pt) {
                    margin-top: auto;
                }
            }

            .toggle-buttons-wrapper {
                @include mq($not-mobile-tablet-pt) {
                    top: -80px;
                    width: auto;
                    position: absolute;
                    padding: 0;
                    right: 0;
                }
            }
        }
    }

    +.experience-campaign-layouts-membership {
        position: relative;
        z-index: 2;

        .membership__component {
            background-color: transparent;

            @include mq($not-mobile-tablet-pt) {
                margin-top: auto;
                padding-top: 0;
            }
        }

        .membership__heading {
            display: none;
        }
    }
}

.membership__component {
    padding: 24px 16px;
    overflow: hidden;

    // PD templates have overflow hidden on the whole page, there this component can have a toggle button etc
    .page-designer-reference & {
        overflow: visible;
    }

    @include mq($not-mobile-tablet-pt) {
        padding: 44px 16px;
    }

    .swiper-wrapper {
        justify-content: space-between;
    }

    .card__price {
        justify-content: flex-start;
        flex-wrap: nowrap;

        span {
            font-size: 32px;
            line-height: 120%;
        }
    }

    .strike-through-price {
        color: $grey-dark;
        font-family: $heading-pro-double-regular;
        font-size: 14px;
        line-height: 130%;

        span {
            font-family: $heading-pro-double-regular;
            font-size: inherit;
            line-height: inherit;
        }
    }

    .usp-list-all,
    .usp-view-less-display-none,
    .usp-view-more-display-none,
    .membership-tile__inner-display-none {
        display: none;
    }

    .usp-view-less,
    .usp-view-more {
        color: $purple-heart;
        font-size: 14px;
        line-height: 22px;
        text-decoration: underline;
        text-transform: none;

        &:hover {
            text-decoration: none;
        }
    }
}

.membership__content {
    margin: 0 auto;
    max-width: 1120px;
    position: relative;
}

.membership__heading {
    color: $safety-orange;
    font-size: 32px;
    line-height: 1.2;
    margin-bottom: 32px;

    span {
        color: $black;
        display: block;
    }
}

.membership__slider {
    &.swiper-initialized {
        cursor: grab;
    }

    // not transition, or it will mess with the swiper calculations
    .membership-tile {
        background-color: $white;
        box-shadow: 0 2px 2px 0 rgb(0 0 0 / 15%);
        height: auto;

        @include block-hover;

        &.weekly-pricing-enabled,
        :has(.membership-tile__inner.weekly-pricing-enabled) & {
            border-radius: 0 0 4px 4px;
        }

        &.swiper-slide {
            @include mq($not-mobile-tablet-pt) {
                transform: none;

                /* two blocks */
                &:first-child:nth-last-child(2),
                &:first-child:nth-last-child(2) ~ .swiper-slide {
                    width: calc(50% - 16px);
                }

                /* three blocks */
                &:first-child:nth-last-child(3),
                &:first-child:nth-last-child(3) ~ .swiper-slide {
                    width: calc(33.3333% - 16px);
                }

                /* four blocks and it's exceptions */
                &:first-child:nth-last-child(4),
                &:first-child:nth-last-child(4) ~ .swiper-slide {
                    width: 274px;
                    flex-shrink: 0;

                    .membership-tile__inner {
                        padding: 36px 24px 24px;
                    }

                    .membership-tile__footer .btn {
                        padding: 12px;
                    }

                    .weekly-price-disclaimer {
                        margin: 24px -24px -24px;
                        max-width: calc(100% + 48px);
                        width: calc(100% + 48px);
                    }
                }
            }

            @include mq($desktop-xxl) {
                /* two blocks */
                &:first-child:nth-last-child(2),
                &:first-child:nth-last-child(2) ~ .swiper-slide {
                    width: calc(50% - 72px);
                }

                /* three blocks */
                &:first-child:nth-last-child(3),
                &:first-child:nth-last-child(3) ~ .swiper-slide {
                    width: calc(33.3333% - 72px);
                }
            }
        }

        &:not(.swiper-slide-active) {
            transform: scaleY(0.95);
            transform-origin: bottom;
            height: auto;
        }
    }
}

.membership-tile__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 36px 32px 24px;
    position: relative;

    &::before {
        content: "";
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        display: block;
        height: 12px;
        // basic  and all-in
        background: linear-gradient(135deg, #cafcf2 0%, #7adec9 80.21%);
    }

    &.greyed-out {
        img {
            filter: grayscale(100%);
        }

        * {
            color: $battleship-grey;
            cursor: default;
        }

        .checkmark-list li::before,
        .subscription-fee span {
            color: $battleship-grey !important;
        }

        .membership-tile__footer {
            .btn {
                background-color: $wild-sand;
                color: $nero;
            }
        }

        &::before {
            background-image: none;
            background-color: $wild-sand;
        }
    }

    &.no-cta {
        .membership-tile__body {
            padding-bottom: 24px;
        }

        .membership-tile__footer {
            .subscription-fee {
                margin-top: 0;
            }
        }
    }

    &.has-top-label {
        &::before {
            border-radius: 8px 8px 0 0;
            top: -20px;
            height: 32px;
            content: "";
        }

        .top-label {
            position: absolute;
            left: 0;
            top: -20px;
            right: 0;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 4px 16px;
            color: $nero;

            .content {
                display: block;
                font-family: $heading-pro-treble-extra-bold;
                font-size: 12px;
                line-height: 11px;
                @include text-truncate;
            }
        }
    }
}

.membership-tile__inner--comfort {
    &::before {
        background: $black;
    }

    .top-label {
        .content {
            color: $white;
        }
    }
}

.membership-tile__inner--premium,
.membership-tile__inner--ultimate {
    &::before {
        background: linear-gradient(312deg, #ffaa30 5.03%, #fe7000 39.34%, #fe7000 100%);
    }

    .top-label {
        .content {
            color: $white;
        }
    }
}

.membership-tile__header {
    display: flex;
}

.membership-tile__title-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.membership-tile__title {
    font-family: $heading-pro-treble-extra-bold;
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 12px;
    position: relative;
    align-self: flex-start;

    :has(.membership-tile__title-block .strike-through-price) & {
        margin-bottom: 0;
    }

    .has-disclaimer & {
        .asterisk {
            display: inline-block;
            color: $grey-dark;
            position: absolute;
            height: 10px;
            width: 10px;
            right: -13px;
            top: -2px;

            &::after {
                content: "*";
            }
        }
    }
}

.membership-tile__image-block {
    flex: 1;
    margin: -57px -32px 0 0;
    max-height: 150px;
    max-width: 135px;
    position: relative;
    z-index: 2;

    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    .badge {
        max-height: 80px;
        max-width: 80px;
        position: absolute;
        right: -10px;
        top: 60px;
    }

    .full-size-badge {
        max-height: 100%;
        max-width: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.membership-tile__period {
    font-size: 14px;
    line-height: 130%;
    color: $anthracite-50;
}

.membership-tile__promo-block {
    display: flex;
    align-items: center;
    height: 68px;

    span {
        font-size: 12px;
        line-height: 130%;
    }
}

.membership-tile__promo-content {
    background-color: $spray;
    border-radius: 24px;
    font-size: 12px;
    line-height: 130%;
    padding: 10px 30px;
    position: relative;
    text-align: center;
    width: 100%;

    &.flex {
        background-color: $nero;
        color: $white;

        .tooltip-modal-icon {
            &::before {
                background-image: url("../../../img/social/icon-tooltip-white.svg");
            }

            // PD templates are included from deeper then static pages
            .page-designer-reference &::before {
                background-image: url("../../../../img/social/icon-tooltip-white.svg");
            }
        }
    }

    .tooltip-modal-icon {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
    }

    >* {
        @include line-clamp;
    }
}

.tooltip-modal-icon {
    position: relative;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;

    &::before {
        display: block;
        content: "";
        background-image: url("../../../img/social/icon-tooltip.svg");
        width: 14px;
        height: 14px;
        left: -3px;
        top: 3px;
    }

    // PD templates are included from deeper then static pages
    .page-designer-reference &::before {
        background-image: url("../../../../img/social/icon-tooltip.svg");
    }

    .tooltip-content {
        display: none;
    }
}

.membership-tile__body {
    flex: 1;
    font-size: 14px;
    margin-top: 10px;
    padding-bottom: 32px;

    li {
        margin-bottom: 8px;
    }

    strong,
    b {
        font-family: $heading-pro-treble-extra-bold;
    }
}

.membership-paragraph-text {
    font-family: $heading-pro-double-regular;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: $black;

    @include line-clamp(3);
}

.usp-intro {
    flex: 1;
    font-size: 14px;
    margin-bottom: 12px;
    line-height: 1.3;

    br {
        display: none;
    }
}

.membership-tile__footer {
    font-size: 14px;
    line-height: 22px;
    display: flex;
    flex-direction: column;

    .btn {
        width: 100%;
        flex-shrink: 0;
    }

    .subscription-fee {
        margin-top: 16px;
        flex: 1;

        strong,
        b {
            font-family: $heading-pro-treble-extra-bold;
        }

        .content-asset {
            display: inline-block;
        }
    }
}

.weekly-price-disclaimer {
    background-color: $wild-sand;
    border-radius: 0 0 4px 4px;
    padding: 8px 16px;
    position: relative;
    left: 0;
    right: 0;
    font-family: $heading-pro-double-regular;
    font-size: 12px;
    line-height: 130%;
    height: 32px;
    margin: 24px -32px -24px;
    max-width: calc(100% + 64px);
    width: calc(100% + 64px);

    @include text-truncate;

    strong,
    b {
        font-family: $heading-pro-treble-extra-bold;
    }
}

.membership__slider-navigation {
    align-items: center;
    justify-content: space-between;
    margin: 32px auto 0;
    max-width: 300px;
    display: none;

    .swiper-pagination {
        position: static;
    }

    .swiper-pagination-bullet {
        background: $silver;
        border-radius: 6px;
        display: inline-block;
        height: 8px;
        margin: auto 10px;
        opacity: 1;
        transition: all 0.2s ease-in-out;
        width: 8px;
        cursor: pointer;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .swiper-pagination-bullet-active {
        background: $safety-orange;
        opacity: 1;
        width: 20px;
    }

    .arrow-prev {
        cursor: pointer;

        &::before {
            @include iconfont-styles;

            content: svg(swiper-arrow);
            display: inline-block;
            position: relative;
            font-size: 16px;
            vertical-align: middle;
            line-height: initial;
            color: $nero;
            width: 16px;
            height: 16px;
            transform: scale(-1, 1);
            margin-right: 9px;
        }
    }

    .swiper-button-disabled {
        opacity: 0;
        cursor: auto;
    }

    .arrow-next {
        cursor: pointer;
        text-align: right;

        &::after {
            @include iconfont-styles;

            content: svg(swiper-arrow);
            display: inline-block;
            position: relative;
            font-size: 16px;
            vertical-align: middle;
            line-height: initial;
            color: $nero;
            width: 16px;
            height: 16px;
            margin-left: 9px;
        }
    }
}

// overrule nesting, to keep the container hidden on load, and only load / show when applicable
:has(.membership__slider-navigation .swiper-pagination-horizontal.swiper-pagination-lock) {
    .membership__slider-navigation {
        display: none;
    }

    .membership__slider {
        &.swiper-initialized {
            cursor: auto;
        }
    }
}

:has(.membership__slider-navigation .swiper-pagination-horizontal) {
    .membership__slider-navigation {
        display: flex;
    }
}

.reduced {
    color: $safety-orange;
}

.membership__disclaimer {
    text-align: center;
    margin: 24px auto 0;
    font-size: 14px;
    line-height: 130%;
    max-width: 1120px;
}

.membership__button-wrapper {
    margin: 24px 0 0;
    text-align: center;

    .btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

// CDP only
.footer-button-wrapper {
    max-width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 16px 0;

    @include mq($tablet-max) {
        padding: 32px 0 0;
    }

    .btn {
        width: 100%;
    }
}
