/** font family **/
@media only screen and (min-width: 768px) {
  /*! "$not-mobile" */
}
@media only screen and (min-width: 901px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (min-width: 1025px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (max-width: 1599px) {
  /*! "until-dt-max" */
}
@media only screen and (max-width: 1366px) {
  /*! "until-dt-med" */
}
@media only screen and (max-width: 1279px) {
  /*! "until-dt-min" */
}
@media only screen and (min-width: 1280px) {
  /*! "dt-min" */
}
@media only screen and (max-width: 1024px) {
  /*! "tablet-max" */
}
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  /*! "tablet-ls" */
}
@media only screen and (max-width: 900px) {
  /*! "mobile-tablet-pt" */
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  /*! "tablet-pt" */
}
@media only screen and (max-width: 767px) {
  /*! "$mobile-max" */
}
@media only screen and (max-width: 415px) {
  /*! "$mobile-pt" */
}
.tooltip-modal-icon,
.discount-label__tooltip-icon,
.discount-label__promotion-icon {
  position: relative;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}
.tooltip-modal-icon::before,
.discount-label__tooltip-icon::before,
.discount-label__promotion-icon::before {
  display: block;
  content: "";
  background-image: url("../../../img/social/icon-tooltip.svg");
  width: 14px;
  height: 14px;
  left: -3px;
  top: 3px;
}
.page-designer-reference .tooltip-modal-icon::before,
.page-designer-reference .discount-label__tooltip-icon::before,
.page-designer-reference .discount-label__promotion-icon::before {
  background-image: url("../../../../img/social/icon-tooltip.svg");
}

.flex-promo .tooltip-modal-icon::before {
  background-image: url("../../../img/social/icon-tooltip-white.svg");
}

.tooltip-modal-icon .tooltip-content {
  display: none;
}

.tt-modal,
.panel-modal {
  display: none;
  position: fixed;
  background: #fff;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-height: 100vh;
  z-index: 101;
}
@media only screen and (min-width: 768px) {
  .tt-modal,
  .panel-modal {
    max-width: 448px;
  }
}
@media only screen and (max-width: 767px) {
  .tt-modal,
  .panel-modal {
    top: initial;
    right: initial;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 90%;
  }
}
.tt-modal.show, .tt-modal.active,
.panel-modal.show,
.panel-modal.active {
  display: block;
}
.tt-modal.from-side,
.panel-modal.from-side {
  display: block;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-out;
  transform: translateX(100%);
}
@media only screen and (max-width: 767px) {
  .tt-modal.from-side,
  .panel-modal.from-side {
    transform: translateX(0);
    transform: translateY(100%);
  }
}
.tt-modal.from-side.show, .tt-modal.from-side.active,
.panel-modal.from-side.show,
.panel-modal.from-side.active {
  opacity: 1;
  transform: translateX(0);
}
@media only screen and (max-width: 767px) {
  .tt-modal.from-side.show, .tt-modal.from-side.active,
  .panel-modal.from-side.show,
  .panel-modal.from-side.active {
    transform: translateY(0);
  }
}
.tt-modal .modal-close,
.panel-modal .modal-close {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;
  border: 0;
}
.tt-modal .modal-close::before,
.panel-modal .modal-close::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: inherit;
  content: "\f112";
  font-size: 16px;
  position: relative;
  z-index: 1;
  pointer-events: all;
}

.tooltip-content,
.panel-modal-content {
  max-width: 100%;
  max-height: 100%;
  padding: 0;
  position: relative;
  height: 100%;
  overflow: auto !important;
  scrollbar-color: #c5c5c5 transparent;
  scrollbar-width: thin;
}
.tooltip-content::-webkit-scrollbar,
.panel-modal-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.tooltip-content::-webkit-scrollbar-track,
.panel-modal-content::-webkit-scrollbar-track {
  background-color: transparent;
}
.tooltip-content::-webkit-scrollbar-thumb,
.panel-modal-content::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
}
.tooltip-content:not(.has-image),
.panel-modal-content:not(.has-image) {
  margin-top: 48px;
  height: calc(100% - 48px);
}
.tooltip-content:not(.has-image) .image-wrapper,
.panel-modal-content:not(.has-image) .image-wrapper {
  display: none;
}
.tooltip-content img,
.panel-modal-content img {
  max-width: 100%;
}
.tooltip-content.has-image .image-wrapper,
.panel-modal-content.has-image .image-wrapper {
  display: block;
  position: relative;
  text-align: center;
  line-height: 0;
}
.tooltip-content.has-image img,
.panel-modal-content.has-image img {
  width: 100%;
  max-width: 448px;
}
.tooltip-content.has-image .text-wrapper,
.panel-modal-content.has-image .text-wrapper {
  padding: 32px;
}
@media only screen and (max-width: 767px) {
  .tooltip-content.has-image .text-wrapper,
  .panel-modal-content.has-image .text-wrapper {
    padding: 16px;
  }
}
.tooltip-content .text-wrapper,
.panel-modal-content .text-wrapper {
  position: relative;
  z-index: 1;
  padding: 16px 32px 32px;
}
@media only screen and (max-width: 767px) {
  .tooltip-content .text-wrapper,
  .panel-modal-content .text-wrapper {
    padding: 8px 16px 16px;
  }
}
.tooltip-content h3.title,
.panel-modal-content h3.title {
  font-family: "HeadingProTreble-ExtraBold", sans-serif;
  font-size: 20px;
  line-height: 120%;
  margin-bottom: 16px;
  text-transform: uppercase;
}
.tooltip-content h3.title.club-detail,
.panel-modal-content h3.title.club-detail {
  font-family: "HeadingProTreble-Heavy", sans-serif;
}
.tooltip-content .text,
.panel-modal-content .text {
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-size: 16px;
  line-height: 150%;
  overflow: hidden;
}
.tooltip-content .text p,
.panel-modal-content .text p {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0 0 12px;
}
.tooltip-content .text ul,
.tooltip-content .text ol,
.panel-modal-content .text ul,
.panel-modal-content .text ol {
  margin: revert;
  list-style: revert;
  padding-left: 8px;
}
.tooltip-content .text ul:first-child,
.tooltip-content .text ol:first-child,
.panel-modal-content .text ul:first-child,
.panel-modal-content .text ol:first-child {
  margin-top: 0;
}
.tooltip-content .text ul:last-child,
.tooltip-content .text ol:last-child,
.panel-modal-content .text ul:last-child,
.panel-modal-content .text ol:last-child {
  margin-bottom: 0;
}
.tooltip-content .text h4,
.panel-modal-content .text h4 {
  font-family: "HeadingProTreble-ExtraBold", sans-serif;
  font-size: 16px;
  line-height: 120%;
  margin: 0 0 12px;
}
.tooltip-content .text .styled-list,
.panel-modal-content .text .styled-list {
  counter-reset: list-number;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 0;
}
.tooltip-content .text .styled-list > li,
.panel-modal-content .text .styled-list > li {
  display: flex;
  gap: 15px;
  align-items: center;
}
.tooltip-content .text .styled-list > li::before,
.panel-modal-content .text .styled-list > li::before {
  counter-increment: list-number;
  content: counter(list-number);
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  color: #fff;
  background-color: #2d2d2d;
  font-family: "HeadingProTreble-ExtraBold", sans-serif;
  font-size: 20px;
  align-self: flex-start;
}
.tooltip-content .text table,
.panel-modal-content .text table {
  width: 100%;
  margin: 0 0 12px;
}
.tooltip-content .text table td:last-child,
.panel-modal-content .text table td:last-child {
  text-align: right;
  white-space: nowrap;
}
.tooltip-content a:not(.btn),
.panel-modal-content a:not(.btn) {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  text-decoration: underline;
}
.tooltip-content a:not(.btn):hover,
.panel-modal-content a:not(.btn):hover {
  text-decoration: none;
}

body.pm-overlay {
  overflow-y: hidden;
}
body.pm-overlay .panel-modal-overlay {
  background-color: #313136;
  opacity: 0.45;
  width: 100%;
  height: 1000%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 42;
  bottom: auto;
}
body.tt-popup-overlay {
  overflow-y: hidden;
}
body.tt-popup-overlay .tt-overlay {
  background-color: #313136;
  opacity: 0.45;
  width: 100%;
  height: 1000%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 42;
  bottom: auto;
}

.toggle-buttons-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 0 26px;
}
@media only screen and (min-width: 901px) {
  .toggle-buttons-wrapper {
    justify-content: flex-end;
    padding: 0 0 40px;
  }
}

:has(.membership-tile__inner.has-top-label) .toggle-buttons-wrapper {
  padding: 0 0 36px;
}
@media only screen and (min-width: 901px) {
  :has(.membership-tile__inner.has-top-label) .toggle-buttons-wrapper {
    padding: 0 0 40px;
  }
}

.toggle-buttons {
  position: relative;
  display: flex;
  background-color: #fff;
  width: 132px;
  height: 40px;
  padding: 4px;
  border-radius: 70px;
  cursor: pointer;
  z-index: 1;
}
.toggle-buttons.toggle-year--active::before, .toggle-buttons.toggle-flex--active::before {
  content: "";
  height: 32px;
  width: 60px;
  border-radius: 70px;
  background-color: #6131ad;
  display: block;
  position: absolute;
  top: 4px;
  z-index: -1;
  transition: left 0.1s ease-in-out;
}
.toggle-buttons.toggle-year--active::before {
  left: 4px;
}
.toggle-buttons.toggle-flex--active::before {
  left: 64px;
}

.toggle-button {
  font-family: "HeadingProTreble-ExtraBold", sans-serif;
  font-size: 12px;
  font-weight: 700;
  height: 32px;
  width: 60px;
  border-color: transparent;
  border-radius: 44px;
  color: #2d2d2d;
  background-color: transparent;
  transition: color 0.1s ease-in-out;
  padding-left: 0;
  padding-right: 0;
}

.toggle-button--active {
  color: #fff;
  background-color: transparent;
}

.experience-campaign-assets-herocampaignbanner + .experience-campaign-layouts-membership {
  position: relative;
  top: -50px;
  z-index: 2;
}
.experience-campaign-assets-herocampaignbanner + .experience-campaign-layouts-membership .membership__component {
  padding: 0 16px;
  background-color: transparent;
}
.experience-campaign-assets-herocampaignbanner + .experience-campaign-layouts-membership .membership__heading {
  display: none;
}
@media only screen and (min-width: 901px) {
  .experience-campaign-assets-herocampaignbanner + .experience-campaign-layouts-membership .toggle-buttons-wrapper {
    top: -80px;
    width: auto;
    position: absolute;
    padding: 0;
    right: 0;
  }
}

.content-page-banner.next-component-up + .experience-campaign-layouts-membership .membership__component {
  margin-top: 0;
  padding-top: 0;
}
@media only screen and (min-width: 901px) {
  .content-page-banner.next-component-up + .experience-campaign-layouts-membership .membership__component {
    margin-top: auto;
  }
}
@media only screen and (min-width: 901px) {
  .content-page-banner.next-component-up + .experience-campaign-layouts-membership .toggle-buttons-wrapper {
    top: -80px;
    width: auto;
    position: absolute;
    padding: 0;
    right: 0;
  }
}
.content-page-banner + .experience-campaign-layouts-membership {
  position: relative;
  z-index: 2;
}
.content-page-banner + .experience-campaign-layouts-membership .membership__component {
  background-color: transparent;
}
@media only screen and (min-width: 901px) {
  .content-page-banner + .experience-campaign-layouts-membership .membership__component {
    margin-top: auto;
    padding-top: 0;
  }
}
.content-page-banner + .experience-campaign-layouts-membership .membership__heading {
  display: none;
}

.membership__component {
  padding: 24px 16px;
  overflow: hidden;
}
.page-designer-reference .membership__component {
  overflow: visible;
}
@media only screen and (min-width: 901px) {
  .membership__component {
    padding: 44px 16px;
  }
}
.membership__component .swiper-wrapper {
  justify-content: space-between;
}
.membership__component .card__price {
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.membership__component .card__price span {
  font-size: 32px;
  line-height: 120%;
}
.membership__component .strike-through-price {
  color: #6e6e6e;
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-size: 14px;
  line-height: 130%;
}
.membership__component .strike-through-price span {
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-size: inherit;
  line-height: inherit;
}
.membership__component .usp-list-all,
.membership__component .usp-view-less-display-none,
.membership__component .usp-view-more-display-none,
.membership__component .membership-tile__inner-display-none {
  display: none;
}
.membership__component .usp-view-less,
.membership__component .usp-view-more {
  color: #6131ad;
  font-size: 14px;
  line-height: 22px;
  text-decoration: underline;
  text-transform: none;
}
.membership__component .usp-view-less:hover,
.membership__component .usp-view-more:hover {
  text-decoration: none;
}

.membership__content {
  margin: 0 auto;
  max-width: 1120px;
  position: relative;
}

.membership__heading {
  color: #fe7000;
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: 32px;
}
.membership__heading span {
  color: #313136;
  display: block;
}

.membership__slider.swiper-initialized {
  cursor: grab;
}
.membership__slider .membership-tile {
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  height: auto;
}
@media (hover: hover) and (pointer: fine) {
  .membership__slider .membership-tile:hover {
    box-shadow: 0 4px 30px 0 rgba(146, 146, 146, 0.65);
  }
}
.membership__slider .membership-tile.weekly-pricing-enabled, :has(.membership-tile__inner.weekly-pricing-enabled) .membership__slider .membership-tile {
  border-radius: 0 0 4px 4px;
}
@media only screen and (min-width: 901px) {
  .membership__slider .membership-tile.swiper-slide {
    transform: none;
    /* two blocks */
    /* three blocks */
    /* four blocks and it's exceptions */
  }
  .membership__slider .membership-tile.swiper-slide:first-child:nth-last-child(2), .membership__slider .membership-tile.swiper-slide:first-child:nth-last-child(2) ~ .swiper-slide {
    width: calc(50% - 16px);
  }
  .membership__slider .membership-tile.swiper-slide:first-child:nth-last-child(3), .membership__slider .membership-tile.swiper-slide:first-child:nth-last-child(3) ~ .swiper-slide {
    width: calc(33.3333% - 16px);
  }
  .membership__slider .membership-tile.swiper-slide:first-child:nth-last-child(4), .membership__slider .membership-tile.swiper-slide:first-child:nth-last-child(4) ~ .swiper-slide {
    width: 274px;
    flex-shrink: 0;
  }
  .membership__slider .membership-tile.swiper-slide:first-child:nth-last-child(4) .membership-tile__inner, .membership__slider .membership-tile.swiper-slide:first-child:nth-last-child(4) ~ .swiper-slide .membership-tile__inner {
    padding: 36px 24px 24px;
  }
  .membership__slider .membership-tile.swiper-slide:first-child:nth-last-child(4) .membership-tile__footer .btn, .membership__slider .membership-tile.swiper-slide:first-child:nth-last-child(4) ~ .swiper-slide .membership-tile__footer .btn {
    padding: 12px;
  }
  .membership__slider .membership-tile.swiper-slide:first-child:nth-last-child(4) .weekly-price-disclaimer, .membership__slider .membership-tile.swiper-slide:first-child:nth-last-child(4) ~ .swiper-slide .weekly-price-disclaimer {
    margin: 24px -24px -24px;
    max-width: calc(100% + 48px);
    width: calc(100% + 48px);
  }
}
@media only screen and (min-width: 1400px) {
  .membership__slider .membership-tile.swiper-slide {
    /* two blocks */
    /* three blocks */
  }
  .membership__slider .membership-tile.swiper-slide:first-child:nth-last-child(2), .membership__slider .membership-tile.swiper-slide:first-child:nth-last-child(2) ~ .swiper-slide {
    width: calc(50% - 72px);
  }
  .membership__slider .membership-tile.swiper-slide:first-child:nth-last-child(3), .membership__slider .membership-tile.swiper-slide:first-child:nth-last-child(3) ~ .swiper-slide {
    width: calc(33.3333% - 72px);
  }
}
.membership__slider .membership-tile:not(.swiper-slide-active) {
  transform: scaleY(0.95);
  transform-origin: bottom;
  height: auto;
}

.membership-tile__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 36px 32px 24px;
  position: relative;
}
.membership-tile__inner::before {
  content: "";
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  display: block;
  height: 12px;
  background: linear-gradient(135deg, #cafcf2 0%, #7adec9 80.21%);
}
.membership-tile__inner.greyed-out img {
  filter: grayscale(100%);
}
.membership-tile__inner.greyed-out * {
  color: #595a59;
  cursor: default;
}
.membership-tile__inner.greyed-out .checkmark-list li::before,
.membership-tile__inner.greyed-out .subscription-fee span {
  color: #595a59 !important;
}
.membership-tile__inner.greyed-out .membership-tile__footer .btn {
  background-color: #ece9e8;
  color: #2d2d2d;
}
.membership-tile__inner.greyed-out::before {
  background-image: none;
  background-color: #ece9e8;
}
.membership-tile__inner.no-cta .membership-tile__body {
  padding-bottom: 24px;
}
.membership-tile__inner.no-cta .membership-tile__footer .subscription-fee {
  margin-top: 0;
}
.membership-tile__inner.has-top-label::before {
  border-radius: 8px 8px 0 0;
  top: -20px;
  height: 32px;
  content: "";
}
.membership-tile__inner.has-top-label .top-label {
  position: absolute;
  left: 0;
  top: -20px;
  right: 0;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4px 16px;
  color: #2d2d2d;
}
.membership-tile__inner.has-top-label .top-label .content {
  display: block;
  font-family: "HeadingProTreble-ExtraBold", sans-serif;
  font-size: 12px;
  line-height: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.membership-tile__inner--comfort::before {
  background: #313136;
}
.membership-tile__inner--comfort .top-label .content {
  color: #fff;
}

.membership-tile__inner--premium::before,
.membership-tile__inner--ultimate::before {
  background: linear-gradient(312deg, #ffaa30 5.03%, #fe7000 39.34%, #fe7000 100%);
}
.membership-tile__inner--premium .top-label .content,
.membership-tile__inner--ultimate .top-label .content {
  color: #fff;
}

.membership-tile__header {
  display: flex;
}

.membership-tile__title-block {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.membership-tile__title {
  font-family: "HeadingProTreble-ExtraBold", sans-serif;
  font-size: 16px;
  line-height: 120%;
  margin-bottom: 12px;
  position: relative;
  align-self: flex-start;
}
:has(.membership-tile__title-block .strike-through-price) .membership-tile__title {
  margin-bottom: 0;
}
.has-disclaimer .membership-tile__title .asterisk {
  display: inline-block;
  color: #6e6e6e;
  position: absolute;
  height: 10px;
  width: 10px;
  right: -13px;
  top: -2px;
}
.has-disclaimer .membership-tile__title .asterisk::after {
  content: "*";
}

.membership-tile__image-block {
  flex: 1;
  margin: -57px -32px 0 0;
  max-height: 150px;
  max-width: 135px;
  position: relative;
  z-index: 2;
}
.membership-tile__image-block img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.membership-tile__image-block .badge {
  max-height: 80px;
  max-width: 80px;
  position: absolute;
  right: -10px;
  top: 60px;
}
.membership-tile__image-block .full-size-badge {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.membership-tile__period {
  font-size: 14px;
  line-height: 130%;
  color: #808080;
}

.membership-tile__promo-block {
  display: flex;
  align-items: center;
  height: 68px;
}
.membership-tile__promo-block span {
  font-size: 12px;
  line-height: 130%;
}

.membership-tile__promo-content {
  background-color: #7adec9;
  border-radius: 24px;
  font-size: 12px;
  line-height: 130%;
  padding: 10px 30px;
  position: relative;
  text-align: center;
  width: 100%;
}
.membership-tile__promo-content.flex {
  background-color: #2d2d2d;
  color: #fff;
}
.membership-tile__promo-content.flex .tooltip-modal-icon::before {
  background-image: url("../../../img/social/icon-tooltip-white.svg");
}
.page-designer-reference .membership-tile__promo-content.flex .tooltip-modal-icon::before {
  background-image: url("../../../../img/social/icon-tooltip-white.svg");
}
.membership-tile__promo-content .tooltip-modal-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.membership-tile__promo-content > * {
  overflow: hidden;
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.tooltip-modal-icon {
  position: relative;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}
.tooltip-modal-icon::before {
  display: block;
  content: "";
  background-image: url("../../../img/social/icon-tooltip.svg");
  width: 14px;
  height: 14px;
  left: -3px;
  top: 3px;
}
.page-designer-reference .tooltip-modal-icon::before {
  background-image: url("../../../../img/social/icon-tooltip.svg");
}
.tooltip-modal-icon .tooltip-content {
  display: none;
}

.membership-tile__body {
  flex: 1;
  font-size: 14px;
  margin-top: 10px;
  padding-bottom: 32px;
}
.membership-tile__body li {
  margin-bottom: 8px;
}
.membership-tile__body strong,
.membership-tile__body b {
  font-family: "HeadingProTreble-ExtraBold", sans-serif;
}

.membership-paragraph-text {
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #313136;
  overflow: hidden;
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.usp-intro {
  flex: 1;
  font-size: 14px;
  margin-bottom: 12px;
  line-height: 1.3;
}
.usp-intro br {
  display: none;
}

.membership-tile__footer {
  font-size: 14px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
}
.membership-tile__footer .btn {
  width: 100%;
  flex-shrink: 0;
}
.membership-tile__footer .subscription-fee {
  margin-top: 16px;
  flex: 1;
}
.membership-tile__footer .subscription-fee strong,
.membership-tile__footer .subscription-fee b {
  font-family: "HeadingProTreble-ExtraBold", sans-serif;
}
.membership-tile__footer .subscription-fee .content-asset {
  display: inline-block;
}

.weekly-price-disclaimer {
  background-color: #ece9e8;
  border-radius: 0 0 4px 4px;
  padding: 8px 16px;
  position: relative;
  left: 0;
  right: 0;
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-size: 12px;
  line-height: 130%;
  height: 32px;
  margin: 24px -32px -24px;
  max-width: calc(100% + 64px);
  width: calc(100% + 64px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.weekly-price-disclaimer strong,
.weekly-price-disclaimer b {
  font-family: "HeadingProTreble-ExtraBold", sans-serif;
}

.membership__slider-navigation {
  align-items: center;
  justify-content: space-between;
  margin: 32px auto 0;
  max-width: 300px;
  display: none;
}
.membership__slider-navigation .swiper-pagination {
  position: static;
}
.membership__slider-navigation .swiper-pagination-bullet {
  background: #c5c5c5;
  border-radius: 6px;
  display: inline-block;
  height: 8px;
  margin: auto 10px;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  width: 8px;
  cursor: pointer;
}
.membership__slider-navigation .swiper-pagination-bullet:first-child {
  margin-left: 0;
}
.membership__slider-navigation .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.membership__slider-navigation .swiper-pagination-bullet-active {
  background: #fe7000;
  opacity: 1;
  width: 20px;
}
.membership__slider-navigation .arrow-prev {
  cursor: pointer;
}
.membership__slider-navigation .arrow-prev::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: inherit;
  content: "\f136";
  display: inline-block;
  position: relative;
  font-size: 16px;
  vertical-align: middle;
  line-height: initial;
  color: #2d2d2d;
  width: 16px;
  height: 16px;
  transform: scale(-1, 1);
  margin-right: 9px;
}
.membership__slider-navigation .swiper-button-disabled {
  opacity: 0;
  cursor: auto;
}
.membership__slider-navigation .arrow-next {
  cursor: pointer;
  text-align: right;
}
.membership__slider-navigation .arrow-next::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: inherit;
  content: "\f136";
  display: inline-block;
  position: relative;
  font-size: 16px;
  vertical-align: middle;
  line-height: initial;
  color: #2d2d2d;
  width: 16px;
  height: 16px;
  margin-left: 9px;
}

:has(.membership__slider-navigation .swiper-pagination-horizontal.swiper-pagination-lock) .membership__slider-navigation {
  display: none;
}
:has(.membership__slider-navigation .swiper-pagination-horizontal.swiper-pagination-lock) .membership__slider.swiper-initialized {
  cursor: auto;
}

:has(.membership__slider-navigation .swiper-pagination-horizontal) .membership__slider-navigation {
  display: flex;
}

.reduced {
  color: #fe7000;
}

.membership__disclaimer {
  text-align: center;
  margin: 24px auto 0;
  font-size: 14px;
  line-height: 130%;
  max-width: 1120px;
}

.membership__button-wrapper {
  margin: 24px 0 0;
  text-align: center;
}
.membership__button-wrapper .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.footer-button-wrapper {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 16px 0;
}
@media only screen and (max-width: 1024px) {
  .footer-button-wrapper {
    padding: 32px 0 0;
  }
}
.footer-button-wrapper .btn {
  width: 100%;
}