//  the tooltip and panel modal from checkout step 2 and 3 but also in PD components and presale

.tooltip-modal-icon,
.discount-label__tooltip-icon,
.discount-label__promotion-icon {
    position: relative;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;

    &::before {
        display: block;
        content: "";
        background-image: url("../../../img/social/icon-tooltip.svg");
        width: 14px;
        height: 14px;
        left: -3px;
        top: 3px;
    }

    // PD templates are included from deeper then static pages
    .page-designer-reference &::before {
        background-image: url("../../../../img/social/icon-tooltip.svg");
    }
}

//  used on the checkout only for now
.flex-promo {
    .tooltip-modal-icon {
        &::before {
            background-image: url("../../../img/social/icon-tooltip-white.svg");
        }
    }
}

.tooltip-modal-icon {
    .tooltip-content {
        display: none;
    }
}

.tt-modal,
.panel-modal {
    display: none;
    position: fixed;
    background: $white;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    max-height: 100vh;
    z-index: 101;

    @include mq($not-mobile) {
        max-width: 448px;
    }

    @include mq($mobile-max) {
        top: initial;
        right: initial;
        left: 0;
        bottom: 0;
        width: 100%;
        max-height: 90%;
    }

    &.show,
    &.active {
        display: block;
    }

    &.from-side {
        // already display block, work with transform
        display: block;
        // use opacity to hide it while resizing
        opacity: 0;
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-out;
        transform: translateX(100%);

        @include mq($mobile-max) {
            transform: translateX(0);
            transform: translateY(100%);
        }

        &.show,
        &.active {
            opacity: 1;
            // mobile from bottom
            transform: translateX(0);

            @include mq($mobile-max) {
                transform: translateY(0);
            }
        }
    }

    .modal-close {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        background-color: $white;
        cursor: pointer;
        z-index: 2;
        border: 0;

        &::before {
            @include iconfont-styles;

            content: svg(close2);
            font-size: 16px;
            position: relative;
            z-index: 1;
            pointer-events: all;
        }
    }
}

.tooltip-content,
.panel-modal-content {
    max-width: 100%;
    max-height: 100%;
    padding: 0;
    position: relative;
    height: 100%;
    overflow: auto !important;

    @include scrollbar;

    &:not(.has-image) {
        // prevent overlap with cross
        margin-top: 48px;
        height: calc(100% - 48px);

        // fallback:
        .image-wrapper {
            display: none;
        }
    }

    img {
        max-width: 100%;
    }

    &.has-image {
        .image-wrapper {
            display: block;
            position: relative;
            text-align: center;
            line-height: 0;
        }

        img {
            width: 100%;
            max-width: 448px;
        }

        .text-wrapper {
            padding: 32px;

            @include mq($mobile-max) {
                padding: 16px;
            }
        }
    }

    .text-wrapper {
        position: relative;
        z-index: 1;
        padding: 16px 32px 32px;

        @include mq($mobile-max) {
            padding: 8px 16px 16px;
        }
    }

    h3.title {
        font-family: $heading-pro-treble-extra-bold;
        font-size: 20px;
        line-height: 120%;
        margin-bottom: 16px;
        text-transform: uppercase;

        &.club-detail {
            font-family: $heading-pro-treble-heavy;
        }
    }

    .text {
        font-family: $heading-pro-double-regular;
        font-size: 16px;
        line-height: 150%;
        overflow: hidden;

        p {
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
            margin: 0 0 12px;
        }

        ul,
        ol {
            margin: revert;
            list-style: revert;
            padding-left: 8px;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        h4 {
            font-family: $heading-pro-treble-extra-bold;
            font-size: 16px;
            line-height: 120%;
            margin: 0 0 12px;
        }

        .styled-list {
            counter-reset: list-number;
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding-left: 0;

            > li {
                display: flex;
                gap: 15px;
                align-items: center;

                &::before {
                    counter-increment: list-number;
                    content: counter(list-number);
                    display: inline-flex;
                    flex-shrink: 0;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 30px;
                    color: $white;
                    background-color: $nero;
                    font-family: $heading-pro-treble-extra-bold;
                    font-size: 20px;
                    align-self: flex-start;
                }
            }
        }

        table {
            width: 100%;
            margin: 0 0 12px;

            td:last-child {
                text-align: right;
                white-space: nowrap;
            }
        }
    }

    a:not(.btn) {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        text-transform: none;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

// page overlays as modal backdrops:
body {
    // panel  modal
    &.pm-overlay {
        overflow-y: hidden;

        .panel-modal-overlay {
            background-color: $black;
            opacity: 0.45;
            width: 100%;
            height: 1000%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 42;
            bottom: auto;
        }
    }

    &.tt-popup-overlay {
        overflow-y: hidden;

        .tt-overlay {
            background-color: $black;
            opacity: 0.45;
            width: 100%;
            height: 1000%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 42;
            bottom: auto;
        }
    }
}
