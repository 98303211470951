.toggle-buttons-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 0 26px;

    @include mq($not-mobile-tablet-pt) {
        justify-content: flex-end;
        padding: 0 0 40px;
    }
}

:has(.membership-tile__inner.has-top-label) {
    .toggle-buttons-wrapper {
        padding: 0 0 36px;

        @include mq($not-mobile-tablet-pt) {
            padding: 0 0 40px;
        }
    }
}

.toggle-buttons {
    position: relative;
    display: flex;
    background-color: $white;
    width: 132px;
    height: 40px;
    padding: 4px;
    border-radius: 70px;
    cursor: pointer;
    z-index: 1;

    &.toggle-year--active,
    &.toggle-flex--active {
        &::before {
            content: "";
            height: 32px;
            width: 60px;
            border-radius: 70px;
            background-color: $purple-heart;
            display: block;
            position: absolute;
            top: 4px;
            z-index: -1;
            transition: left 0.1s ease-in-out;
        }
    }

    &.toggle-year--active {
        &::before {
            left: 4px;
        }
    }

    &.toggle-flex--active {
        &::before {
            left: 64px;
        }
    }
}

.toggle-button {
    font-family: $heading-pro-treble-extra-bold;
    font-size: 12px;
    font-weight: 700;
    height: 32px;
    width: 60px;
    border-color: transparent;
    border-radius: 44px;
    color: $nero;
    background-color: transparent;
    transition: color 0.1s ease-in-out;
    padding-left: 0;
    padding-right: 0;
}

.toggle-button--active {
    color: $white;
    background-color: transparent;
}
